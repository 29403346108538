import React from "react"
import { Link } from "gatsby"

import { rhythm } from "../utils/typography"

const BlogPosts = ( {posts} ) => {
  return ( 
    <div>
      <h1>
        Sometime I write blog posts
      </h1>
      <p>
        They aren't fantastically edited, but hey, here they are
      </p>
      {posts.map(({ node }) => {
        const title = node.frontmatter.title || node.fields.slug
        return (
          <article key={node.fields.slug}>
            <header>
              <h3
                style={{
                  marginBottom: rhythm(1 / 4),
                }}
              >
                <Link style={{ boxShadow: `none` }} to={node.fields.slug}>
                  {title}
                </Link>
              </h3>
              <small>{node.frontmatter.date}</small>
            </header>
            <section>
              <p
                dangerouslySetInnerHTML={{
                  __html: node.frontmatter.description || node.excerpt,
                }}
              />
            </section>
          </article>
        )
      })}
    </div>
  )
}

export default BlogPosts
